<template>
	<nav v-if="pagination.length > 1">
		<ul v-if="mdAndGreater" class="pagination" :class="{ [paginationClass]: true }">
			<li class="page-item" :class="{ disabled: pageNumber <= 1 }">
				<button class="btn page-link" 
					:disabled="disabled || pageNumber <= 1" 
					@click="handlePrev">
					<Icon symbol="chevron-left"></Icon>
				</button>
			</li>

			<li v-for="item in pagination" class="page-item" :class="{ active: item.isActive }" :key="pagination.indexOf(item)">
				<button
					class="btn page-link"
					@click="handleGoToPage(+item.val)"
					:disabled="disabled || item.val === pageNumber"
					v-if="item.val !== '...'"
				>
					{{ item.name ? item.name : item.val }}
				</button>
				<span v-if="item.val == '...'">
					{{ item.val }}
				</span>
			</li>

			<li class="page-item" :class="{ disabled: pageNumber >= totalPages }">
				<button class="btn page-link"
					:disabled="disabled || pageNumber >= totalPages"
					@click="handleNext">
					<Icon symbol="chevron-right"></Icon>
				</button>
			</li>
		</ul>
        <div v-else class="d-flex align-items-center gap-2">
            <p class="caption flex-shrink-0 mb-0">Jump to</p>
            <select
                class="form-select form-select-sm w-auto"
                @change="handleSelectChange"
                :value="pageNumber">
                <option disabled hidden>Please select</option>
                <option v-for="page in totalPages" :value="page">{{ page }}</option>
            </select>
        </div>
	</nav>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { Icon } from '@/modules/core/components';
import { breakpointsBootstrapV5, useBreakpoints } from "@vueuse/core";

interface PageItem {
	name?: string;
	val: number|string;
	isActive: boolean;
}

const props = defineProps({
    pageNumber: {
        type: Number,
        required: true,
    },
    totalPages: {
        type: Number,
        required: true,
    },
    pagesPerView: {
        type: Number,
        default: 5,
    },
    pagesPerViewMd: {
        type: Number,
        default: 10
    },
    paginationClass: {
        type: String,
        default: ""
    },
    disabled: Boolean
})

const emit = defineEmits<{
    (e: 'pageChange', value: number): void
}>()

const breakpoints = useBreakpoints(breakpointsBootstrapV5);
const mdAndGreater = breakpoints.greater('md');

const buttonsPerView = computed((): number => {
    if (mdAndGreater.value) {
        return props.pagesPerViewMd
    }
    return props.pagesPerView;
});

const pagination = computed((): Array<PageItem> => {
    const range: PageItem[] = [];
    if (props.totalPages <= buttonsPerView.value) {
        for (let i = 0; i < props.totalPages; i++) {
            range.push({
                val: i + 1,
                isActive: props.pageNumber === i + 1,
            });
        }
    }
    else {
        // if totalPages > pagesPerView (default: 10)
        // get max index difference
        let maxIndex = props.totalPages - buttonsPerView.value;
        // set start as largest Number possible of pageNumber
        let start = Math.max(props.pageNumber - (buttonsPerView.value / 2), 0);
        // ensure not too far from each other (lowest value)
        start = Math.min(maxIndex, start);
        for (let i = start; i < buttonsPerView.value + start; i++) {
            range.push({
                val: i + 1,
                isActive: props.pageNumber === i + 1,
            });
        }
        // when start !== 0, use ellipsis conditionally
        if (start > 0) {
            let firstLabel = "First";
            range.unshift({
                name: firstLabel,
                val: 1,
                isActive: false,
            }, {
                val: "...",
                isActive: false,
            });
        }
        if (start < maxIndex) {
            let lastLabel = "Last";
            range.push({
                val: "...",
                isActive: false,
            }, {
                name: lastLabel,
                val: props.totalPages,
                isActive: false,
            });
        }
    }
    return range;
});

const handlePrev = () => {
    emit('pageChange', Math.max(0, props.pageNumber - 1));
}

const handleGoToPage = (pageNumber: number) => {
    emit('pageChange', pageNumber);
}

const handleNext = () => {
    emit('pageChange', Math.min(props.totalPages, props.pageNumber + 1));
}

const handleSelectChange = (event: Event) => {
    const target = event.target as HTMLSelectElement;
    emit('pageChange', Number(target.value))
}
</script>

<style lang="scss">

.pagination {
    margin-bottom: 0;

	.page-item {
		margin-right: .125rem;

		&:first-child,
		&:last-child {
			.page-link {
				background-color: transparent;
			}
		}

		.page-link {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0;
			height: 2.25rem;
			width: 2.25rem;
			border: 0;
			font-weight: 500;
			font-size: .875rem;
			background-color: transparent;
            color: $black;

			&:hover {
				background-color: tint-color($primary, 80%);
			}

			&:active {
				background-color: $primary;
				color: #fff;
			}
		}

		&.active {
            $primary-rgb: red($primary), green($primary), blue($primary);

			.page-link {
				background-color: var(--bs-primary, #{$primary});
                color: $white;
                box-shadow: 0px 8px 18px -8px rgb(var(--bs-primary-rgb, #{$primary-rgb}));
				opacity: 1;
			}
		}

		&.disabled {
			.page-link {
				background-color: transparent;
			}
		}

		&:last-child {
			margin-right: 0;
		}
	}

    @include media-breakpoint-up (md) {
        .page-item {
            margin-right: .4rem;
        }
    }
}

</style>
